import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import HeroBanner from "../components/HomePage/HeroBanner";
import banner from "../assets/images/faq/hero.png";
import TextAnimation from "../components/own-a-studio/TextAnimation";
import useFetch from "../hooks/useFetch";
import ErrorBoundary from "../utils/ErrorBoundary";
function Faq() {
  const legalInfo = [
    { title: "Privacy Policy" },
    { title: "Terms and conditions" },
    { title: "Membership policy" },
    { title: "Offers Policy" },
  ];
  const { data, loading } = useFetch({
    params: "faq",
    populate:
      "topBanner, topBanner.image,topBanner.mobileImage, frequentlyQuestionTitle,frequentlyQuestionDescription,frequentlyQuestion,frequentlyQuestion.questions ",
  });
  return (
    <ErrorBoundary>

      <Layout>
        <HeroBanner
          title={data?.data?.attributes?.topBanner?.title || "FAQ"}
          img={data?.data?.attributes?.topBanner?.image?.data?.attributes?.url || banner}
          bannerMobile={data?.data?.attributes?.topBanner?.mobileImage?.data?.attributes?.url}
        />
        <TextAnimation />
        <div className="my-[82px] details-tag-after  max-w-[800px] mx-6 lg:mx-auto">
          <div className="w-full">
            <p className="heading text-center text-black">
              {data?.data?.attributes?.frequentlyQuestionTitle ||
                "Frequently Asked Quesitons"}
            </p>
            <p className="body text-center text-black max-w-[674px] mx-auto my-[54px]">
              {data?.data?.attributes?.frequentlyQuestionDescription ||
                ` Welcome to our FAQ section. Here, we have compiled a list of
              frequently asked questions to help you better understand our
            products/services and how we operate. If you can't find the answer
            you're looking for, please feel free to contact us and we'll be
            happy to assist you.at Rebalance and find answers to common
            questions`}
            </p>
          </div>

          {data?.data?.attributes?.frequentlyQuestion?.map((i, idx) => {
            return (
              <div className="flex mt-6 flex-col lg:w-[670px] mx-auto">
                <h2
                  className="uppercase text-black subheading3 mb-7"
                  style={{ fontSize: "20px" }}
                >
                  {i?.title || "ant"}
                </h2>
                {i?.questions.map((j, index) => (
                  <details key={index} className="border-t border-t-lemonadeSecondary  first:border-t first:border-t-lemonadeSecondary last:border-b last:border-b-lemonadeSecondary text-black rounded-[5px] py-6 pl-12 pr-6 outline-none  ">
                    <summary
                      style={{ fontSize: "18px" }}
                      className="subheading pl-6 cursor-pointer"
                    >
                      {j.question || "Can I bring a friend?"}
                    </summary>
                    <p className=" pt-[18px] text-black body  pl-6">{j.anwser}</p>
                  </details>
                ))}
              </div>
            );
          })}
        </div>
      </Layout>
    </ErrorBoundary>
  );
}

export default Faq;
